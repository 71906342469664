<template>
  <div class="container has-text-centered" id="capture-finished">
    <div style="position: relative" class="is-flex is-justify-content-center">
      <img :src="imgSrc" />
      <div
        class="circle-1 is-flex is-justify-content-center is-align-items-center"
      >
        <div
          class="circle-2 is-flex is-justify-content-center is-align-items-center"
        >
          <div
            class="circle-3 is-flex is-justify-content-center is-align-items-center"
          >
            <div
              class="circle-4 is-flex is-justify-content-center is-align-items-center"
            >
              <b-icon icon="check" type="is-success"></b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6 pt-4">
      <p class="mt-4 mb-4">Done! Thank you for taking your picture</p>
      <b-button
        icon-left="check"
        type="is-primary"
        @click.native="closeModal"
        v-if="showButtonDone"
      >
        Done
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    showButtonDone: Boolean,
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
