<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">General Setting</h2>
    </template>
    <template #page-content>
      <div id="general-setting">
        <div class="columns is-multiline is-mobile" v-if="currentUser">
          <div class="column is-4">
            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p class="has-text-weight-semi-bold">
                  Maximum Change Schedule (days per month)
                </p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingMaxChangeSchedule"
                >
                  <form
                    @submit.prevent="handleSubmit(saveChangeSchedule)"
                    class="is-label-14-px-normal has-text-weight-semi-bold"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="maximum change schedule"
                          v-slot="{ errors }"
                          rules="required|numeric"
                        >
                          <b-input
                            placeholder="e.g. 12"
                            v-model="maxChangeSchedule"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingMaxChangeSchedule"
                          :disabled="disabledSaveMaxChangeSchedule"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <b-skeleton animated v-else />
              </div>
            </div>

            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isCompanyOwner || currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p class="has-text-weight-semi-bold">
                  Mandatory Selfie when clock in and clock out
                </p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingSelfieMandatory"
                >
                  <form
                    @submit.prevent="handleSubmit(saveSelfieMandatory)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="selfie mandatory"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-switch
                            v-model="isSelfieMandatory"
                            type="is-success"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingSelfieMandatory"
                          :disabled="disabledSaveSelfieMandatory"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div>

            <!-- 18/09/2023 -- Fitur ini di hide sesuai ticket RISE-2225 -->
            <!-- <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p class="has-text-weight-semi-bold">
                  Face Recognition Mandatory
                </p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingFaceRegMandatory"
                >
                  <form
                    @submit.prevent="handleSubmit(saveFaceRegMandatory)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="face recognition mandatory"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-switch
                            v-model="isFaceRegMandatory"
                            type="is-success"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingFaceRegMandatory"
                          :disabled="disabledSaveFaceRegMandatory"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div> -->

            <!-- 18/09/2023 -- Fitur ini di hide sesuai ticket RISE-2225 -->
            <!-- <div class="columns is-multiline is-mobile">
              <div class="column is-12 is-mobile pb-0">
                <p class="has-text-weight-semi-bold">Set Face Recognition</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingSetImageFaceReg"
                >
                  <form
                    @submit.prevent="handleSubmit(saveFaceRegMandatory)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <p>Please take a picture to recognize your identity</p>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          type="primary"
                          @click.native="openModal"
                        >
                          {{ imageFaceReg.url ? 'Re-capture' : 'Start' }}
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div> -->

            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p class="has-text-weight-semi-bold">Cut Off Date</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingCutoffDate"
                >
                  <form
                    @submit.prevent="handleSubmit(saveCutoffDate)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="cutoff date"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <div>
                            <b-radio
                              v-model="isEndMonth"
                              name="cutoff"
                              class="pl-0 column is-8"
                              :native-value="true"
                            >
                              Last day in the month
                            </b-radio>
                            <b-radio
                              v-model="isEndMonth"
                              name="cutoff"
                              class="pl-0 column is-8"
                              :native-value="false"
                            >
                              Custom
                            </b-radio>
                            <p class="has-text-danger">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          name="custom cutoff date"
                          v-slot="{ errors }"
                          rules="required|integer|between:1,31"
                        >
                          <b-input
                            class="pl-5 column is-7"
                            placeholder="e.g. day between 1-31"
                            v-model="cutoffDate"
                            :disabled="isEndMonth"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingCutoffDate"
                          :disabled="disabledSaveCutoffDate"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <b-skeleton animated v-else />
              </div>
            </div>

            <div class="columns is-mobile" v-if="currentUser.isAdmin">
              <div class="column is-12 is-multiline pb-0">
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingLateHourSetting"
                >
                  <form
                    @submit.prevent="handleSubmit(saveLateHourSetting)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <p class="has-text-weight-semi-bold">Clock In Schedule</p>
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="clock in schedule"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <div>
                            <b-radio
                              v-model="isDefaultClockinSchedule"
                              name="clock in schedule"
                              class="pl-0 column is-8"
                              :native-value="true"
                            >
                              08.00 AM
                            </b-radio>
                            <b-radio
                              v-model="isDefaultClockinSchedule"
                              name="clock in schedule"
                              class="pl-0 column is-8"
                              :native-value="false"
                            >
                              Custom
                            </b-radio>
                            <p class="has-text-danger">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          name="custom clock in schedule"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-timepicker
                            class="pl-5 column is-7"
                            placeholder="Click to edit..."
                            hour-format="24"
                            v-model="clockinSchedule"
                            enable-seconds
                            editable
                            :disabled="isDefaultClockinSchedule"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>

                    <p class="has-text-weight-semi-bold">
                      Late Clock In Tolerance
                    </p>
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="late clock in tolerance"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <div>
                            <b-radio
                              v-model="isDefaultClockinTolerance"
                              name="late clock in tolerance"
                              class="pl-0 column is-8"
                              :native-value="true"
                            >
                              30 minutes
                            </b-radio>
                            <b-radio
                              v-model="isDefaultClockinTolerance"
                              name="late clock in tolerance"
                              class="pl-0 column is-8"
                              :native-value="false"
                            >
                              Custom
                            </b-radio>
                            <p class="has-text-danger">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          name="custom late clock in tolerance"
                          v-slot="{ errors }"
                          rules="required|integer"
                        >
                          <div class="columns">
                            <div class="column">
                              <div class="columns is-12">
                                <div class="column is-7">
                                  <b-input
                                    class="pl-5 pt-2"
                                    placeholder="e.g. in minute"
                                    v-model="clockinTolerance"
                                    :disabled="isDefaultClockinTolerance"
                                  />
                                </div>
                                <div class="column minutes-label">minutes</div>
                              </div>
                            </div>
                          </div>
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingLateHourSetting"
                          :disabled="disabledSaveLateHour"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="loading-container" v-else>
                  <p>Clock In Schedule</p>
                  <b-skeleton animated />
                  <p>Late Clock In Tolerance</p>
                  <b-skeleton animated />
                </div>
              </div>

              <div class="column is-12 is-offset-4 is-multiline">
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingOvertimeHourSetting"
                >
                  <form
                    @submit.prevent="handleSubmit(saveOvertimeHourSetting)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <p class="has-text-weight-semi-bold">Clock Out Schedule</p>
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="clock out schedule"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <div>
                            <b-radio
                              v-model="isDefaultClockoutSchedule"
                              name="clock out schedule"
                              class="pl-0 column is-8"
                              :native-value="true"
                            >
                              8 hours after clock in
                            </b-radio>
                            <b-radio
                              v-model="isDefaultClockoutSchedule"
                              name="clock out schedule"
                              class="pl-0 column is-8"
                              :native-value="false"
                            >
                              Custom
                            </b-radio>
                            <p class="has-text-danger">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          name="custom clock out schedule"
                          v-slot="{ errors }"
                          rules="required|integer|between:0,24"
                        >
                          <b-input
                            class="pl-5 column is-7"
                            placeholder="e.g. in hour"
                            v-model="clockoutSchedule"
                            :disabled="isDefaultClockoutSchedule"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>

                    <p class="has-text-weight-semi-bold">
                      Late Clock Out Tolerance
                    </p>
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="late clock out tolerance"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <div>
                            <b-radio
                              v-model="isDefaultClockoutTolerance"
                              name="late clock out tolerance"
                              class="pl-0 column is-8"
                              :native-value="true"
                            >
                              30 minutes
                            </b-radio>
                            <b-radio
                              v-model="isDefaultClockoutTolerance"
                              name="late clock out tolerance"
                              class="pl-0 column is-8"
                              :native-value="false"
                            >
                              Custom
                            </b-radio>
                            <p class="has-text-danger">{{ errors[0] }}</p>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          name="custom late clock out tolerance"
                          v-slot="{ errors }"
                          rules="required|integer"
                        >
                          <div class="columns">
                            <div class="column">
                              <div class="columns is-12">
                                <div class="column is-7">
                                  <b-input
                                    class="pl-5 pt-2"
                                    placeholder="e.g. in minute"
                                    v-model="clockoutTolerance"
                                    :disabled="isDefaultClockoutTolerance"
                                  />
                                </div>
                                <div class="column minutes-label">minutes</div>
                              </div>
                            </div>
                          </div>
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingOvertimeHourSetting"
                          :disabled="disabledSaveOvertimeHour"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="loading-container" v-else>
                  <p>Clock Out Schedule</p>
                  <b-skeleton animated />
                  <p>Late Clock Out Tolerance</p>
                  <b-skeleton animated />
                </div>
              </div>
            </div>
            <div class="columns is-multiline" v-if="currentUser.isAdmin">
              <p class="column is-12 has-text-weight-semi-bold">
                Assign Release Note Feature
              </p>
              <div class="column is-10">
                <b-autocomplete
                  icon-right="account"
                  placeholder="Select User"
                  :data="assignee"
                  :loading="isFetching"
                  :check-infinite-scroll="true"
                  field="fullName"
                  @typing="getAsyncData"
                  @select="selectUser"
                  @infiniteScroll="getMoreAsyncData"
                  v-model="search"
                >
                  <template slot-scope="props">
                    <div class="media-content">
                      {{ props.option.fullName }}
                    </div>
                  </template>
                </b-autocomplete>
              </div>
              <div class="column is-2">
                <b-button
                  class="is-primary"
                  type="submit"
                  :loading="isLoadingReleaseNoteSave"
                  @click.native="submitReleaseNoteAssignee"
                  :disabled="selected === oldSelected"
                >
                  Save
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          @close="closeModal"
          :active="isModalFaceCamOpen"
          trap-focus
          has-modal-card
        >
          <template #default="props">
            <FaceCam
              action="takePicture"
              @close="props.close"
              @submit="saveImageFaceReg"
              :submit-loading="isSavingFaceReg"
              :is-capture-finished="isCaptureFinished"
              :is-close-face-cam="isCloseFaceCam"
              show-button-capture-finished
            ></FaceCam>
          </template>
        </b-modal>
      </div>
    </template>
  </r-page>
</template>

<script>
import { convertToUTC, showToast } from '../../services/util'
import ApiService from '../../services/common/api.service'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import FaceCam from '@/components/FaceCam'
import apiService from '../../services/common/api.service'
import debounce from 'lodash/debounce'
export default {
  components: {
    FaceCam,
  },
  watch: {
    currentUser() {
      if (this.lateHourSettingId > 0) {
        let utcMoment = moment(this.clockinTimeUTC)
        this.clockinSchedule = new Date(utcMoment.format('YYYY-MM-DD HH:mm:ss'))
      }
    },
    isEndMonth: function () {
      if (this.isEndMonth) {
        this.cutoffDate = this.endMonth
      }
    },
    isDefaultClockinSchedule: async function () {
      if (this.isDefaultClockinSchedule) {
        this.clockinSchedule = await this.convertUtcMoment(
          this.defaultClockinSchedule
        )
      }
    },
    isDefaultClockinTolerance: function () {
      if (this.isDefaultClockinTolerance) {
        this.clockinTolerance = this.defaultClockinTolerance
      }
    },
    isDefaultClockoutSchedule: async function () {
      if (this.isDefaultClockoutSchedule) {
        this.clockoutSchedule = this.defaultClockoutSchedule
      }
    },
    isDefaultClockoutTolerance: function () {
      if (this.isDefaultClockoutTolerance) {
        this.clockoutTolerance = this.defaultClockoutTolerance
      }
    },
    maxChangeSchedule: function (val) {
      this.disabledSaveMaxChangeSchedule =
        parseInt(val) === this.oldMaxChangeSchedule
    },
    isFaceRegMandatory: function (val) {
      this.disabledSaveFaceRegMandatory = val === this.oldFaceRegMandatory
    },
    isSelfieMandatory: function (val) {
      this.disabledSaveSelfieMandatory = val === this.oldSelfieMandatory
    },
    cutoffDate: function (val) {
      this.disabledSaveCutoffDate = parseInt(val) === this.oldCutoffDate
    },
    clockinSchedule: function () {
      this.disabledSaveLateHour = this.compareLateHourSettings()
    },
    clockinTolerance: function () {
      this.disabledSaveLateHour = this.compareLateHourSettings()
    },
    clockoutSchedule: function () {
      this.disabledSaveOvertimeHour = this.compareOvertimeHourSettings()
    },
    clockoutTolerance: function () {
      this.disabledSaveOvertimeHour = this.compareOvertimeHourSettings()
    },
  },
  data() {
    return {
      clockinSchedule: null,
      oldClockinSchedule: null,
      clockinTolerance: null,
      oldClockinTolerance: null,
      clockinTimeUTC: undefined,
      lateHourSettingId: 0,
      isLoadingLateHourSetting: false,
      isSavingLateHourSetting: false,
      disabledSaveLateHour: true,

      clockoutSchedule: null,
      oldClockoutSchedule: null,
      clockoutTolerance: null,
      oldClockoutTolerance: null,
      overtimeHourSettingId: 0,
      isLoadingOvertimeHourSetting: false,
      isSavingOvertimeHourSetting: false,
      disabledSaveOvertimeHour: true,

      maxChangeSchedule: null,
      oldMaxChangeSchedule: null,
      maxChangeScheduleSettingId: 0,
      isLoadingMaxChangeSchedule: false,
      isSavingMaxChangeSchedule: false,
      disabledSaveMaxChangeSchedule: true,

      isLoadingSelfieMandatory: false,
      isSavingSelfieMandatory: false,
      isSelfieMandatory: false,
      oldSelfieMandatory: false,
      disabledSaveSelfieMandatory: true,

      isLoadingFaceRegMandatory: false,
      isLoadingReleaseNoteSave: false,
      isFaceRegMandatory: false,
      oldFaceRegMandatory: null,
      isSavingFaceRegMandatory: false,
      settingSavingFaceRegId: 0,
      disabledSaveFaceRegMandatory: true,

      isModalFaceCamOpen: false,
      isLoadingSetImageFaceReg: false,
      isSavingFaceReg: false,
      imageFaceReg: {
        url: null,
      },
      isCaptureFinished: false,
      isCloseFaceCam: false,

      cutoffDate: null,
      oldCutoffDate: null,
      cutoffDateSettingId: 0,
      isLoadingCutoffDate: false,
      isSavingCutoffDate: false,
      disabledSaveCutoffDate: true,

      isEndMonth: null,
      endMonth: 31,

      isDefaultClockinSchedule: null,
      isDefaultClockinTolerance: null,
      defaultClockinSchedule: '01:00:00',
      defaultClockinTolerance: 30,

      isDefaultClockoutSchedule: null,
      isDefaultClockoutTolerance: null,
      defaultClockoutSchedule: 8,
      defaultClockoutTolerance: 30,

      // Assignee Data
      assignee: [],
      selected: null,
      search: null,
      isFetching: false,
      name: '',
      page: 1,
      totalPages: 1,
      oldSelected: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  methods: {
    openModal() {
      this.isCloseFaceCam = false
      this.isModalFaceCamOpen = true
    },
    dataURLtoFile(dataurl, filename) {
      // https://stackoverflow.com/questions/16968945/convert-base64-png-data-to-javascript-file-objects/16972036
      // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    async saveImageFaceReg(image) {
      this.isSavingFaceReg = true
      try {
        let file = this.dataURLtoFile(image, 'facereg.png')

        let form = new FormData()
        form.append('image', file)

        await apiService.setHeaderMultipartFormData()
        let response = await ApiService.post(
          '/api/space-roketin/user/face-reg-file',
          form,
          true
        )

        this.imageFaceReg.url = response.data.data.faceRegFileUrl

        showToast('Image saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      apiService.resetHeader()
      this.isSavingFaceReg = false
      this.isCaptureFinished = true
    },
    closeModal() {
      this.isCloseFaceCam = true
      this.isModalFaceCamOpen = false
      this.isCaptureFinished = false
    },
    async saveChangeSchedule() {
      this.isSavingMaxChangeSchedule = true
      try {
        if (this.maxChangeScheduleSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/schedule-limit',
            {
              limitRate: this.maxChangeSchedule,
            }
          )
          this.maxChangeScheduleSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/schedule-limit/${this.maxChangeScheduleSettingId}`,
            {
              limitRate: this.maxChangeSchedule,
            }
          )
        }
        this.oldMaxChangeSchedule = this.maxChangeSchedule
        this.disabledSaveMaxChangeSchedule = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }

      this.isSavingMaxChangeSchedule = false
    },
    toUtc(dateTime) {
      return convertToUTC(dateTime)
    },
    async saveLateHourSetting() {
      this.isSavingLateHourSetting = true
      let utcTime = convertToUTC(this.clockinSchedule).format('HH:mm:ss')
      try {
        if (this.lateHourSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/company-late-hour',
            {
              time: utcTime,
              tolerance: this.clockinTolerance ?? 0,
            }
          )
          this.lateHourSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/company-late-hour/${this.lateHourSettingId}`,
            {
              time: utcTime,
              tolerance: this.clockinTolerance ?? 0,
            }
          )
        }
        this.oldClockinSchedule = this.clockinSchedule
        this.oldClockinTolerance = this.clockinTolerance
        this.disabledSaveLateHour = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isSavingLateHourSetting = false
    },
    async saveSelfieMandatory() {
      this.isSavingSelfieMandatory = true
      try {
        await ApiService.post(
          '/api/space-roketin/settings/company/selfie-toggle',
          {
            is_selfie_required: this.isSelfieMandatory,
          }
        )
        this.oldSelfieMandatory = this.isSelfieMandatory
        this.disabledSaveSelfieMandatory = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
      }
      this.isSavingSelfieMandatory = false
    },
    async saveFaceRegMandatory() {
      this.isSavingFaceRegMandatory = true
      try {
        if (this.settingSavingFaceRegId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/face-recognition-mandatory',
            {
              isMandatory: this.isFaceRegMandatory ? 1 : 0,
            }
          )
          this.settingSavingFaceRegId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/face-recognition-mandatory/${this.settingSavingFaceRegId}`,
            {
              isMandatory: this.isFaceRegMandatory ? 1 : 0,
            }
          )
        }
        this.oldFaceRegMandatory = this.isFaceRegMandatory
        this.disabledSaveFaceRegMandatory = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }

      this.isSavingFaceRegMandatory = false
    },

    async saveCutoffDate() {
      this.isSavingCutoffDate = true
      try {
        if (this.cutoffDateSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/cutoff',
            {
              cutoffDay: this.cutoffDate,
            }
          )
          this.cutoffDateSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/cutoff/${this.cutoffDateSettingId}`,
            {
              cutoffDay: this.cutoffDate,
            }
          )
        }
        this.oldCutoffDate = this.cutoffDate
        this.disabledSaveCutoffDate = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }

      this.isSavingCutoffDate = false
    },

    async saveOvertimeHourSetting() {
      this.isSavingOvertimeHourSetting = true
      try {
        if (this.overtimeHourSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/company-overtime-hour',
            {
              time: this.clockoutSchedule,
              tolerance: this.clockoutTolerance ?? 0,
            }
          )
          this.overtimeHourSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/company-overtime-hour/${this.overtimeHourSettingId}`,
            {
              time: this.clockoutSchedule,
              tolerance: this.clockoutTolerance ?? 0,
            }
          )
        }
        this.oldClockoutSchedule = this.clockoutSchedule
        this.oldClockoutTolerance = this.clockoutTolerance
        this.disabledSaveOvertimeHour = true
        showToast('Saved', 'is-success', 'is-top')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isSavingOvertimeHourSetting = false
    },

    async fetchLateHourSetting() {
      this.isLoadingLateHourSetting = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/settings/company-late-hour'
        )
        if (response.data[0]) {
          this.lateHourSettingId = response.data[0].id

          this.clockinSchedule = await this.convertUtcMoment(
            response.data[0].time
          )
          this.clockinTolerance = response.data[0].tolerance

          this.oldClockinSchedule = this.clockinSchedule
          this.oldClockinTolerance = this.clockinTolerance

          this.isDefaultClockinSchedule =
            response.data[0].time === this.defaultClockinSchedule
          this.isDefaultClockinTolerance =
            response.data[0].tolerance === this.defaultClockinTolerance
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isLoadingLateHourSetting = false
    },
    async fetchMaxChangeSchedule() {
      this.isLoadingMaxChangeSchedule = true
      let response = await ApiService.get(
        '/api/space-roketin/settings/schedule-limit'
      )
      if (response.data[0]) {
        this.maxChangeSchedule = response.data[0].limitRate
        this.oldMaxChangeSchedule = this.maxChangeSchedule
        this.maxChangeScheduleSettingId = response.data[0].id
      }
      this.isLoadingMaxChangeSchedule = false
    },
    async fetchSelfieMandatory() {
      this.isLoadingSelfieMandatory = true
      let response = await ApiService.post(
        '/api/space-roketin/attendance/attendance-condition',
        {}
      )
      if (response.data.data) {
        this.isSelfieMandatory = !!response.data.data.isSelfieRequired
        this.oldSelfieMandatory = this.isSelfieMandatory
      }
      this.isLoadingSelfieMandatory = false
    },
    async fetchFaceRecogMandatory() {
      this.isLoadingFaceRegMandatory = true
      let response = await ApiService.get(
        '/api/space-roketin/settings/face-recognition-mandatory'
      )
      if (response.data[0]) {
        this.isFaceRegMandatory = !!response.data[0].isMandatory
        this.oldFaceRegMandatory = this.isFaceRegMandatory
        this.settingSavingFaceRegId = response.data[0].id
      }
      this.isLoadingFaceRegMandatory = false
    },
    async fetchImageFaceReg() {
      this.isLoadingSetImageFaceReg = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/user/face-reg-file'
        )
        this.imageFaceReg.url = response.data.faceRegFileUrl
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isLoadingSetImageFaceReg = false
    },
    async fetchCutoffDate() {
      this.isLoadingCutoffDate = true
      let response = await ApiService.get('/api/space-roketin/settings/cutoff')
      if (response.data[0]) {
        this.cutoffDate = response.data[0].cutoffDay
        this.oldCutoffDate = this.cutoffDate
        this.cutoffDateSettingId = response.data[0].id
        if (this.cutoffDate === this.endMonth) {
          this.isEndMonth = true
        } else {
          this.isEndMonth = false
        }
      }
      this.isLoadingCutoffDate = false
    },
    async fetchOvertimeHourSetting() {
      this.isLoadingOvertimeHourSetting = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/settings/company-overtime-hour'
        )
        if (response.data[0]) {
          this.overtimeHourSettingId = response.data[0].id
          this.clockoutSchedule = response.data[0].time
          this.clockoutTolerance = response.data[0].tolerance

          this.oldClockoutSchedule = this.clockoutSchedule
          this.oldClockoutTolerance = this.clockoutTolerance

          this.isDefaultClockoutSchedule =
            response.data[0].time === this.defaultClockoutSchedule
          this.isDefaultClockoutTolerance =
            response.data[0].tolerance === this.defaultClockoutTolerance
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        } else {
          console.log(err)
        }
      }
      this.isLoadingOvertimeHourSetting = false
    },
    async convertUtcMoment(time) {
      let splitted = time.split(':')
      let utcTime = new Date()
      utcTime.setUTCHours(splitted[0], splitted[1], splitted[2])
      this.clockinTimeUTC = utcTime
      let utcMoment = moment(this.clockinTimeUTC)

      return new Date(utcMoment.format('YYYY-MM-DD HH:mm:ss'))
    },
    compareLateHourSettings() {
      let clockinSchedule = moment(this.clockinSchedule).format('HH:mm:ss')
      let oldClockinSchedule = moment(this.oldClockinSchedule).format(
        'HH:mm:ss'
      )
      return (
        clockinSchedule === oldClockinSchedule &&
        parseInt(this.clockinTolerance) === this.oldClockinTolerance
      )
    },
    compareOvertimeHourSettings() {
      return (
        parseInt(this.clockoutSchedule) === this.oldClockoutSchedule &&
        parseInt(this.clockoutTolerance) === this.oldClockoutTolerance
      )
    },

    compareAssigneeId() {
      return this.selected === this.oldSelected
    },

    async checkCurrentAssignee() {
      let response = await ApiService.get(
        '/api/space-roketin/release-note/current-user'
      )

      this.selected = response.data.data.id
      this.oldSelected = response.data.data.id
      this.search =
        response.data.data.firstName +
        ' ' +
        response.data.data.middleName +
        ' ' +
        response.data.data.lastName
    },

    selectUser(option) {
      if (option) {
        this.search = option.fullName
        this.selected = option.id
      }
      console.log(this.search)
    },

    // Assignee methods
    getAsyncData: debounce(function (name) {
      // string update
      if (this.name !== name) {
        this.name = name
        this.assignee = []
        this.page = 1
        this.totalPages = 1
      }

      // clear string
      if (!name.length) {
        this.assignee = []
        this.page = 1
        this.totalPages = 1
        return
      }

      // reached last page
      if (this.page > this.totalPages) {
        return
      }

      this.isFetching = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.name,
        page: this.page,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.assignee.push(item))
          this.page++
          this.totalPages = response.data.meta.lastPage

          console.log(this.assignee)
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    getMoreAsyncData: debounce(function () {
      this.getAsyncData(this.name)
    }, 250),

    async submitReleaseNoteAssignee() {
      this.isLoadingReleaseNoteSave = true
      console.log(this.selected)
      await ApiService.post(
        '/api/space-roketin/release-note/grant-access/' + this.selected,
        {},
        true
      )
        .then((response) => {
          showToast(response.data.message, 'is-success', 'is-top')
        })
        .catch((error) => {
          showToast(error.data.message, 'is-danger', 'is-top')
          throw error
        })
        .finally(() => {
          this.oldSelected = this.selected
          this.isLoadingReleaseNoteSave = false
        })
    },
  },
  mounted() {
    this.checkCurrentAssignee()
    this.fetchLateHourSetting()
    this.fetchMaxChangeSchedule()
    this.fetchSelfieMandatory()
    this.fetchFaceRecogMandatory()
    this.fetchImageFaceReg()
    this.fetchCutoffDate()
    this.fetchOvertimeHourSetting()
  },
}
</script>

<style scoped>
.minutes-label {
  padding-top: 1.75rem;
}
</style>
